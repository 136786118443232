import type { AcmSdkConfig, AcmSdkFunction } from '@dce-front/sdk-acm';
import { useAcmSdkContext } from './useAcmSdkContext';

/** Required parameters for a given ACM api (omit config keys) */
type RequiredApiParameters<T extends AcmSdkFunction> = Omit<
  Parameters<T>[0],
  keyof AcmSdkConfig
>;

/** Required parameters for a given ACM api with a partial config */
type RequiredParametersWithPartialConfig<T extends AcmSdkFunction> =
  RequiredApiParameters<T> & Partial<AcmSdkConfig>;

/**
 * Hook to fetch an ACM SDK function\
 * The config is automatically injected from the context, but can be overriden
 *
 * ⚠️ To be used in a `acmSdkContext` provider
 *
 * @param acmSdkFunction an ACM SDK function (exposed by **@dce-front/sdk-acm**)
 * @param requiredParametersWithPartialConfig The required parameters for the function, with a partial config
 * @returns an ACM SDK function with config and parameters
 */
export const useFetchAcmSdk = <T extends AcmSdkFunction>(
  acmSdkFunction: T,
  requiredParametersWithPartialConfig: RequiredParametersWithPartialConfig<T>,
): (() => ReturnType<T>) => {
  const sdkConfig = useAcmSdkContext();

  // Because `T` is an union of ACM SDK functions, the waited parameters and the return type are an union too
  // It's hard to infer with Typescript, but we can safely cast them
  return () =>
    acmSdkFunction({
      ...sdkConfig,
      ...requiredParametersWithPartialConfig,
    } as any) as ReturnType<T>;
};
