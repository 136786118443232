import {
  AcmLockedSafetyCodeError,
  checkParentalCode as checkParentalCodeFunction,
  checkPurchaseCode as checkPurchaseCodeFunction,
} from '@dce-front/sdk-acm';
import { useFetchAcmSdk } from '@dce-front/sdk-acm-react';
import { SafetyCodeContext } from '@dce-front/types-acm';
import { useSelector } from 'react-redux';
import { passTokenSelector } from '../../store/slices/user-selectors';

type UseSubmitSafetyCodeParameters = {
  /** Type of safety code */
  safetyCodeContext: SafetyCodeContext;
  /** Digits entered by user */
  code: string;
  /** Purchase id for PurchaseCode check */
  purchaseId?: string;
  /** Callback when code is locked */
  onLockedCode?: () => void;
  /** Callback when code is wrong */
  onWrongCode?: () => void;
  /** Callback when code is valid (validationToken given by purchaseCode check) */
  onSuccessCode?: (validationToken?: string) => void;
};

/**
 * Hook to submit safety code. It can handle parental code or purchase code.
 * @return a function to submit the code for check using ACM-SDK.
 * @example const handleSubmit = useSubmitSafetyCode({
 *     code: '1234',
 *     safetyCodeContext: SafetyCodeContext.Parental,
 *     onSuccessCode: () => { console.log('success');}
 *   });
 */
export const useSubmitSafetyCode = ({
  code,
  purchaseId,
  safetyCodeContext,
  onLockedCode,
  onWrongCode,
  onSuccessCode,
}: UseSubmitSafetyCodeParameters): (() => Promise<void>) => {
  const passToken = useSelector(passTokenSelector);
  const isParentalCodeContext =
    safetyCodeContext === SafetyCodeContext.Parental;
  const checkParentalCodeStatus = useFetchAcmSdk(checkParentalCodeFunction, {
    parentalCode: code,
    passToken,
  });
  const checkPurchaseCodeStatus = useFetchAcmSdk(checkPurchaseCodeFunction, {
    passToken,
    purchaseCode: code,
    // We need to use the purchaseId as the contextId like this `purchaseId:${purchaseId}` for turbo
    ...(purchaseId && { contextId: `purchaseId:${purchaseId}` }),
  });

  const handleSubmit = async () => {
    try {
      const safetyCodeCheck = isParentalCodeContext
        ? checkParentalCodeStatus
        : checkPurchaseCodeStatus;
      const { check, validationToken } = await safetyCodeCheck();

      if (check) {
        onSuccessCode?.(validationToken);
      } else {
        onWrongCode?.();
      }
    } catch (error) {
      if (error instanceof AcmLockedSafetyCodeError) {
        onLockedCode?.();
      }
    }
  };

  return handleSubmit;
};
